import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';

export function samePasswordsValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {

    if (!(formGroup instanceof FormGroup)) {
      return null;
    }
    return validateSamePasswords(formGroup);
  };
}


function validateSamePasswords(formGroup: AbstractControl): ValidationErrors | null {

  if(passwordAreDiferents(formGroup)) return { passwordAreDiferents: true };

  return null;
}



function passwordAreDiferents(formGroup: AbstractControl): boolean {


  const newPassword= formGroup.get('newPassword')?.value;
  const newPassword2= formGroup.get('newPassword2')?.value;

  if (newPassword
    && newPassword2
    && newPassword !== newPassword2) {
      return true;
    }

  return false;
}
