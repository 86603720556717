// Angular modules
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UniqueUserNameValidator } from '@security/validators/unique-user-name-validator.service';
import { samePasswordsValidator } from '@validators/password.validator';

// Validators

@Injectable()
export class UserFormGroupHelper {

  constructor(
  ) {

  }

  public getUserFormGroup(): FormGroup {

      return new FormGroup({

        login: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: true,
            asyncValidators: [],
            updateOn: 'change',
          }
        ),

        name: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: true,
            asyncValidators: [],
            updateOn: 'change',
          }
        ),

        code: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.maxLength(255)],
            nonNullable: true,
            asyncValidators: [],
            updateOn: 'change',
          }
        ),

        email: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.email, Validators.maxLength(255)],
            nonNullable: true,
            asyncValidators: [],
            updateOn: 'change',
          }
        ),

        newPassword: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: true,
            asyncValidators: [],
            updateOn: 'change',
          }
        ),
        newPassword2: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: true,
            asyncValidators: [],
            updateOn: 'change',
          }
        ),
        customerId: new FormControl<number | undefined>(
          { value: undefined, disabled: false },
          {
            validators: [],
            nonNullable: true,
            updateOn: 'change',
          }
        ),
        customerName: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.maxLength(255)],
            nonNullable: true,
            updateOn: 'change',
          }
        ),

        roleId: new FormControl<number | undefined>(
          { value: undefined, disabled: false },
          {
            validators: [Validators.required],
            nonNullable: true,
            updateOn: 'change',
          }
        ),
        roleName: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: true,
            updateOn: 'change',
          }
        ),

    }, {
      validators: samePasswordsValidator()
    })
  }
}


